import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../asstes/images/manas.png'

export default function Footer() {
  return (
    <footer className="footer section gray-bg">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 mr-auto col-sm-6">
                    <div className="widget mb-5 mb-lg-0">
                        <div className="logo mb-4">
                            <img src={logo}  className="img-fluid" alt="Manas Hospital Jaipur" />
                        </div>
                        <p>Tempora dolorem voluptatum nam vero assumenda voluptate, facilis ad eos obcaecati tenetur veritatis eveniet distinctio possimus.</p>

                        <ul className="list-inline footer-socials mt-4">
                            <li className="list-inline-item"><Link to="https://www.facebook.com/ManasHospitalJaipur/"><i className="icofont-facebook"></i></Link></li>
                            <li className="list-inline-item"><Link to="https://twitter.com/KhorwalHemraj"><i className="icofont-twitter"></i></Link></li>
                            <li className="list-inline-item"><Link to="https://www.youtube.com/@manashospitaljaipur/videos"><i className="icofont-youtube"></i></Link></li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-2 col-md-6 col-sm-6">
                    <div className="widget mb-5 mb-lg-0">
                        <h4 className="text-capitalize mb-3">Department</h4>
                        <div className="divider mb-4"></div>

                        <ul className="list-unstyled footer-menu lh-35">
                            <li><Link to="#">Surgery </Link></li>
                            <li><Link to="#">Wome's Health</Link></li>
                            <li><Link to="#">Radiology</Link></li>
                            <li><Link to="#">Cardioc</Link></li>
                            <li><Link to="#">Medicine</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-2 col-md-6 col-sm-6">
                    <div className="widget mb-5 mb-lg-0">
                        <h4 className="text-capitalize mb-3">Support</h4>
                        <div className="divider mb-4"></div>

                        <ul className="list-unstyled footer-menu lh-35">
                            <li><Link to="#">Terms & Conditions</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Company Support </Link></li>
                            <li><Link to="#">FAQuestions</Link></li>
                            <li><Link to="#">Company Licence</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget-contact mb-5 mb-lg-0">
                        <h4 className="text-capitalize mb-3">Get in Touch</h4>
                        <div className="divider mb-4"></div>

                        <div className="footer-contact-block mb-4">
                            <div className="icon d-flex align-items-center">
                                <i className="icofont-email mr-3"></i>
                                <span className="h6 mb-0">Support Available for 24/7</span>
                            </div>
                            <h4 className="mt-2"><Link to="mailto:manashospitaljaipur@gmail.com">manashospitaljaipur@gmail.com</Link></h4>
                        </div>

                        <div className="footer-contact-block">
                            <div className="icon d-flex align-items-center">
                                <i className="icofont-support mr-3"></i>
                                <span className="h6 mb-0">Mon to Fri : 08:30 - 18:00</span>
                            </div>
                            <h4 className="mt-2"><Link to="tel:+919799923231">+91 9799923231</Link></h4>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="footer-btm py-4 mt-5">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-6">
                        <div className="copyright">
                            &copy; Copyright Reserved to <span className="text-color">Manas Hospital</span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="subscribe-form text-lg-right mt-5 mt-lg-0">
                            <form action="#" className="subscribe">
                                <input type="text" className="form-control" placeholder="Your Email address" />
                                <Link to="#" className="btn btn-main-2 btn-round-full">Subscribe</Link>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <Link className="backtop js-scroll-trigger" to="#top">
                            <i className="icofont-long-arrow-up"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-12">
            <div className="copyright text-lg-center">
        Distributed by <Link to="https://thecodertrick.com/" target="_blank">Thecodertrick</Link>
            </div>
        </div>
    </footer>
  )
}
