import './asstes/plugins/bootstrap/css/bootstrap.min.css';
import './asstes/plugins/icofont/icofont.min.css';
import './asstes/plugins/slick-carousel/slick/slick.css';
import './asstes/plugins/slick-carousel/slick/slick-theme.css';
import './App.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './pages/Home';
import { BrowserRouter,Routes,Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
          <Route path='/' element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    
      
  );
}

export default App;
